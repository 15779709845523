<template>
    <div class="container">
        <TableMain
            :headerColumns="columns"
            :items="companiesList"
            :hideActions="false"
            :fields="fieldsBySearch"
            @update="updateCompany"
            @getItems="getCompanies"
            :valuesFromRoute="true"
            :itemsMeta="companiesMeta"
            :preloader="preloader"
            :clearable="true"
        />
    </div>
</template>

<script>
import TableMain from "./ui/tables/TableMain";

export default {
    name: "CompaniesTable",
    components: {
        TableMain,
    },
    data() {
        return {
            id: null,
            companiesMeta: [],
            fieldsBySearch: [
                {by: 'custom_id', type: "search"},
                {by: 'name', type: "search"},
                {by: 'network_name', type: "search"},
                {by: 'cinema_name', type: "search"},
                {by: 'type', name: 'type', type: "select", options: [
                        // {
                        //     label: this.$t('All'),
                        //     id: '',
                        //     isActive: true,
                        // },
                        {
                            label: this.$t('Legal entity'),
                            id: 'legal',
                            isActive: true,
                        },
                        {
                            id: 'individual',
                            label: this.$t('Individual'),
                            isActive: true,
                        },
                    ],
                },
            ],
            columns: [
                {
                    id: 0,
                    label: "ID",
                    attribute: "custom_id",
                    sort: false
                },
                {
                    id: 1,
                    label: this.$t('Name'),
                    attribute: "name",
                    sort: false,
                },
                {
                    id: 2,
                    label: this.$t('Network'),
                    attribute: "network_name",
                    sort: false,
                },
                {
                    id: 3,
                    label: this.$t('Cinema'),
                    attribute: "cinema_name",
                    sort: false,
                },
                {
                    id: 4,
                    label: this.$t('Type'),
                    attribute: "type",
                    sort: false,
                },
            ],

            items: ['name', 'cinema'],
            companiesList: [],
            preloader: true
        }
    },
    methods:{
        async getCompanies(query = null){
            if (!query) {
                if (Object.keys(this.$route.query).length == 0) {
                    this.$router.push({ query: Object.assign({}, {page: 1, sort_by: 'id'}) });
                }
                query = this.$route.query
            }

            let resp = await this.api.companies.getCompaniesList(query)
            this.companiesList = resp.data

            this.preloader = false

            this.companiesList.forEach(company => {
                let companyName = [];
                let cinemaName = [];

                if (company.networks.length > 0) {
                    company.networks.forEach(network => {
                        companyName.push(network.name)
                        let names = companyName.join(', ')
                        company.network_name = names
                    })
                }

                if (company.cinemas.length > 0) {
                    company.cinemas.forEach(cinema => {
                        cinemaName.push(cinema.name)
                        let names = cinemaName.join(', ')
                        company.cinema_name = names
                    })
                }
            })

            this.companiesMeta = resp.meta

            this.companiesList.forEach(company => {
                if (company.type === 'legal'){
                    company.type = this.$t('Legal entity')
                } else {
                    company.type = this.$t('Individual')
                }
            })

        },
        updateCompany(id){
            this.$emit("updateCompany", id)
        }
    },
    created() {
        this.getCompanies()
    },
}
</script>

<style scoped lang="scss">

::v-deep{
    .table{
        .table__labelTr th:first-child{
            width: 6%!important;
        }
        .table__labelTr th:last-child{
            width: 6%!important;
        }
        .word-wrap, tr td div{
            font-size: 16px;
            line-height: 1.69;
            color: #646e78;
        }
        .parent td div{
            color: black;
        }
    }

}

    .container{
        padding: 0;
        background-color: white;
        margin-top: 15px;

        .table-companies{
            width: 97%;
            margin: 0 auto;
            position: relative;
            top: 15px;


            .inputs-group{
                height: 63px;
                border-bottom: 1px solid #cad9f3;
                width: 1389%;
            }
            thead{
                background-color: #cad9f3;
                height: 60px;
                vertical-align: middle;
                tr{
                    th{
                        border-bottom: none;
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 900;
                        line-height: 1.5;
                        letter-spacing: 2px;
                        color: #141213;
                    }
                }
            }
        }
    }
</style>
