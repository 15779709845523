<template>
    <div>
        <div class="container">
            <Breads :links="breadLinks"/>
        </div>
        <div class="companies-container container">
            <PageHeader :title="$t('Companies')" :button-caption="$t('Add a company')"
                        @pageHeaderButtonClick="openModal"/>
            <CompaniesTable ref="companiesTable" @updateCompany="updateCompany"/>
        </div>
        <ModalComponent :show="showModal" @close='closeModal' :ask-before-leave="askBeforeLeave">
            <form class="add-company-form" @submit.prevent="submitForm" ref="formAddCompany">
                <div class="form-title">
                    {{ titleForModal }}
                </div>
                <div class="row form-inputs-container">
                    <div class="col-md-6 col-12">
                        <InputComponent
                            class="input-item"
                            name="custom_id"
                            :showLabel="true"
                            placeholder="ID"
                            :required="true"
                            :error="errors.custom_id ? errors.custom_id : ''"
                            v-model="dataForEdit.custom_id"
                        />
                        <InputComponent
                            class="input-item"
                            name="name"
                            :showLabel="true"
                            :placeholder="$t('Name')"
                            :required="true"
                            :error="errors.name ? errors.name : ''"
                            v-model="dataForEdit.name"
                        />
                        <SelectComponent
                            class="input-item"
                            name="type"
                            v-model="selectValue"
                            :value="selectValue"
                            :options="selectOptions"
                            :showLabel="true"
                            :placeholder="$t('Type')"
                            :required="true"
                            :error="errors.type ? errors.type : ''"
                        />
                        <InputComponent
                            class="input-item"
                            name="legal_address"
                            :showLabel="true"
                            :placeholder="$t('Legal address')"
                            :error="errors.legal_address ? errors.legal_address : ''"
                            v-model="dataForEdit.legal_address"
                        />
                        <InputComponent
                            class="input-item"
                            name="actual_address"
                            :showLabel="true"
                            :placeholder="$t('Actual address')"
                            :error="errors.actual_address ? errors.actual_address : ''"
                            v-model="dataForEdit.actual_address"
                        />
                        <InputComponent
                            class="input-item"
                            name="email"
                            :showLabel="true"
                            placeholder="E-Mail"
                            :error="errors.email ? errors.email : ''"
                            v-model="dataForEdit.email"
                        />
                    </div>
                    <div class="col-md-6 col-12">
                        <InputComponent
                            name="bank"
                            class="input-item"
                            :showLabel="true"
                            :placeholder="$t('Bank')"
                            :error="errors.bank ? errors.bank : ''"
                            v-model="dataForEdit.bank"
                        />
                        <InputComponent
                            class="input-item"
                            name="mfo"
                            :showLabel="true"
                            :placeholder="$t('MFIs')"
                            :error="errors.mfo ? errors.mfo : ''"
                            v-model="dataForEdit.mfo"
                        />
                        <InputComponent
                            class="input-item"
                            name="inn"
                            :showLabel="true"
                            :placeholder="$t('TIN')"
                            :error="errors.inn ? errors.inn : ''"
                            v-model="dataForEdit.inn"
                        />
                        <InputComponent
                            class="input-item"
                            name="edrpou"
                            :showLabel="true"
                            :placeholder="$t('USREOU')"
                            :error="errors.edrpou ? errors.edrpou : ''"
                            v-model="dataForEdit.edrpou"
                        />
                        <InputComponent
                            class="input-item"
                            name="certificate_of_vat_payer"
                            :showLabel="true"
                            :placeholder="$t('Certificate of VAT')"
                            :error="errors.certificate_of_vat_payer ? errors.certificate_of_vat_payer : ''"
                            v-model="dataForEdit.certificate_of_vat_payer"
                        />
                        <InputPhoneComponent
                            @input="changePhone"
                            @validatePhone="validatePhone"
                            :error="errors.phone"
                            :showLabel="true"
                            class="input-item"
                            :placeholder="$t('Phone')"
                            v-model="dataForEdit.phone"
                        />
                    </div>
                </div>
                <div class="btn-container">
                    <button type="submit" class="btn-save">
                        {{ $t('Save') }}
                    </button>
                    <button type="button" class="btn-cancel" @click="showModal = false">
                        {{ $t('Cancel') }}
                    </button>
                </div>
            </form>
        </ModalComponent>
    </div>
</template>

<script>
import CompaniesTable from "../components/CompaniesTable";
import Navbar from "../components/ui/Navbar";
import ModalComponent from "@/components/ui/ModalComponent"
import InputComponent from "../components/ui/forms/InputComponent";
import SelectComponent from "../components/ui/forms/SelectComponent";
import Breads from "../components/Breads";
import InputPhoneComponent from "@/components/ui/forms/InputPhoneComponent"
import PageHeader from "@/components/PageHeader";

export default {
    name: "Companies",
    components: {
        CompaniesTable,
        Navbar,
        ModalComponent,
        InputComponent,
        SelectComponent,
        Breads,
        PageHeader,
        InputPhoneComponent
    },
    data() {
        return {
            showModal: false,
            selectOptions: [
                {label: this.$t('Legal entity'), id: 1, key: 'legal'},
                {label: this.$t('Individual'), id: 2, key: 'individual'}
            ],
            selectValue: null,
            errors: {},
            titleForModal: null,
            editModal: null,
            idForEdit: null,
            askBeforeLeave: false,
            inProgress: false,
            dataForEdit: {
                id: null,
                name: null,
                email: null,
                phone: null,
                region_id: null,
                type: null,
                legal_address: null,
                actual_address: null,
                mfo: null,
                edrpou: null,
                inn: null,
                certificate_of_vat_payer: null
            },
            breadLinks: [
                {
                    label: this.$t('Companies'),
                    to: { name: 'Companies' }
                }
            ],
            isPhoneDirty: false,
        }
    },
    watch:{
        dataForEdit: {
            handler: function(){
                this.askBeforeLeave = true
            },
            deep: true
        }
    },
    methods:{
        openModal(){
            this.dataForEdit = {}
            this.errors = {}
            this.showModal = true
            this.titleForModal = this.$t('Add a company')
            this.editModal = false
            setTimeout(() => this.askBeforeLeave = false, 500)
        },
        closeModal(){
            this.showModal = false
            this.errors = {}
            this.selectValue = null
        },
        async submitForm(){
            let formData = new FormData(this.$refs.formAddCompany)
            formData.append('phone', this.dataForEdit.phone)

            if (this.handleError({}) == 1) {
                return false
            }

            formData.delete('type')
            let type = this.selectOptions.find(el => el.id == this.selectValue);
            if (type) {
                formData.append('type', type.key)
                this.dataForEdit.type = type.key
            }

            let resp;

            if (!this.editModal && !this.inProgress){

                this.inProgress = true
                resp = await this.api.companies.sentNewCompanies(formData).catch(this.handleError)
                this.inProgress = false

            } else if (!this.inProgress) {

                this.inProgress = true
                resp = await this.api.companies.updateCompany(this.idForEdit, this.dataForEdit).catch(this.handleError)
                this.inProgress = false
            }

            if (resp) {
                this.selectValue = null
                if (this.editModal){
                    this.toast.success(this.$t('The company has been successfully updated'))
                } else {
                    this.toast.success(this.$t('Company successfully added'))
                }

                this.showModal = false
                this.errors = {}
                this.editModal = false
                await this.$refs.companiesTable.getCompanies()
            }
        },
        handleError(resp) {
            if (this.errors.phone?.length) {
                this.errors.phone = this.$t('Phone Error')
                return 1
            }
            this.errors = {}
            if (resp.statusCode === 422) {
                this.errors = resp.messages
            }
        },
        async updateCompany(id){
            this.dataForEdit = {}
            this.editModal = true
            this.titleForModal = this.$t('Editing companies')

            this.idForEdit = id

            let resp = await this.api.company.getCompantId(id).catch(this.handleError)
            this.dataForEdit = resp.data
            this.showModal = true

            if (this.dataForEdit.type === 'legal'){
                this.selectValue = 1
            } else {
                this.selectValue = 2
            }
            setTimeout(() => this.askBeforeLeave = false, 500)
        },

        changePhone(v) {
            if (this.isPhoneDirty == false && v.length) {
                this.isPhoneDirty = !this.isPhoneDirty
                this.errors.phone = this.$t('Phone Error')
            }
        },

        validatePhone(phoneObject) {
            if (phoneObject.valid === true) {
                this.dataForEdit.phone = phoneObject.number;
                this.errors.phone = ''
            } else if (phoneObject.valid === false) {
                this.dataForEdit.phone = '';
                this.errors.phone = this.$t('Phone Error')
            }
        },
    }
}
</script>

<style scoped lang="scss">
.companies-container {
    //padding: 0;
    //width: 1170px;
    //min-height: 839px;
}

.form-title {
    width: 950px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #141213;
    margin-bottom: 47px;
}

.form-inputs-container {
    .input-item {
        margin-top: 30px;
    }
}

.btn-container{
    display: flex;
    justify-content: center;
    margin: 40px 0;
    .btn-save{
        width: 173px;
        height: 45px;
        border-radius: 2px;
        background-color: $color_darkblue;
        border: none;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: white;
        &:hover{
            color: $color_lightpink;
            box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
        }
    }
    .btn-cancel{
        width: 172px;
        height: 45px;
        margin: 0 0 0 15px;
        padding: 16px 0 15px;
        border-radius: 2px;
        border: solid 1px $color_darkblue;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 2px;
        color: #141213;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        background-color: white;
    }
}

::v-deep {
    .form-inputs-container .input-item{
        margin-top: 23px;
    }
    .select-element__error, .form-group__error{
        position: absolute;
    }
    .form-control{
        height: 45px;
        border-color: #cad9f3;
        border-radius: 2px;
    }
    .modal{
        overflow-y: scroll;
        height: auto;
        min-height: 100%;
    }
    .breadcrumb{
        margin-top: 8px;
    }
    //.select-element__error, .form-group__error{
    //    bottom: auto;
    //    right: auto;
    //}

}
</style>
